import { render, staticRenderFns } from "./TeacherSubmissions.vue?vue&type=template&id=2961686c"
import script from "./TeacherSubmissions.vue?vue&type=script&lang=ts"
export * from "./TeacherSubmissions.vue?vue&type=script&lang=ts"
import style0 from "./TeacherSubmissions.vue?vue&type=style&index=0&id=2961686c&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VDataTable,VIcon,VImg,VList,VListItem,VMenu,VRow,VSheet,VSlideGroup,VSlideItem,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2961686c')) {
      api.createRecord('2961686c', component.options)
    } else {
      api.reload('2961686c', component.options)
    }
    module.hot.accept("./TeacherSubmissions.vue?vue&type=template&id=2961686c", function () {
      api.rerender('2961686c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/TeacherSubmissions.vue"
export default component.exports