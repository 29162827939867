var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "loginForm",
      staticClass: "login",
      class: { "mt-10": _vm.$vuetify.breakpoint.smAndDown },
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.doSignIn.apply(null, arguments)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-toolbar",
                { staticClass: "text-h4 text-center", attrs: { flat: "" } },
                [
                  _c("div", { staticClass: "justify-center fill-width" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.planbook.com/images/planbook-logo-blue.svg",
                        alt: _vm.$t("app.name")
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm.hasInfoMsg
            ? _c(
                "div",
                {
                  staticClass:
                    "my-2 text-center font-weight-light font-italic info-message"
                },
                [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.infoMsg) } }),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "d-inline-block ml-2",
                      attrs: {
                        label: _vm.$t("cancelLabel"),
                        icon: "",
                        "x-small": "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doReset.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("fal fa-times")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.hasErrorMsg
            ? _c("div", {
                staticClass:
                  "my-2 text-center font-weight-light font-italic error-message",
                domProps: { innerHTML: _vm._s(_vm.errorMsg) }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "px-10 py-2" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-6 primary--text",
                  class: { "text-left": _vm.$vuetify.breakpoint.smAndDown }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.hasStudentJoinCode ? _vm.$t("studentLabel") : "") +
                          " "
                      ) +
                      " " +
                      _vm._s(_vm.$t("signInLabel")) +
                      " "
                  )
                ]
              ),
              _c("v-text-field", {
                ref: "username",
                staticClass: "login-input primary-input",
                attrs: {
                  "prepend-inner-icon": "fal fa-user",
                  outlined: "",
                  label: _vm.$t("emailOrUserIdLabel"),
                  readonly: _vm.loading,
                  rules: _vm.requiredRule
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _c("v-text-field", {
                ref: "password",
                staticClass: "login-input primary-input",
                attrs: {
                  "prepend-inner-icon": "fal fa-key",
                  outlined: "",
                  label: _vm.$t("passwordOrKeyLabel"),
                  readonly: _vm.loading,
                  rules: _vm.requiredRule,
                  type: "password"
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "px-10 pb-8 pt-0",
              class: { "welcome-card-actions": _vm.$vuetify.breakpoint.mdAndUp }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    color: "error",
                    dark: "",
                    width: "175px",
                    type: "submit",
                    loading: _vm.loading,
                    value: "login"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("signInLabel")))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass:
                    "welcome-btn-forgot text-decoration-underline font-italic",
                  attrs: { text: "", color: "primary", to: "/auth/forgot" }
                },
                [_vm._v(_vm._s(_vm.$t("forgotPasswordLabel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showSignUpButton
        ? [
            _c("v-divider", { staticClass: "mx-10" }),
            _c(
              "div",
              { staticClass: "mt-8 text-center fill-width" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text",
                    attrs: { color: "accent1", depressed: "" },
                    on: { click: _vm.redirectToSignUp }
                  },
                  [_vm._v(_vm._s(_vm.$t("signUpForANewAccountLabel")))]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("pb-confirm", { ref: "confirm" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }